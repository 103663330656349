import React from "react";
import { Link } from "react-router-dom";

type HomeLinkProps = {
  url: string;
  children: React.ReactNode;
};

const HomeLinkComponent: React.FC<HomeLinkProps> = ({
  url,
  children,
  ...props
}) => {
  // Temporary component until we switch all the reports to V2
  const isV1 = url && url.endsWith(".php");

  if (isV1) {
    return (
      <a href={url} {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <Link to={url} {...props}>
        {children}
      </Link>
    );
  }
};

export default HomeLinkComponent;
