import React, { useMemo, useState } from "react";
import styled from 'styled-components';
import { toast } from "react-toastify";
import { Csku, Button, colors } from "@commonsku/styles";
import { useHasCapabilities } from "../hooks";

export interface BulkAction<T extends Record<string, unknown> = Record<string, unknown>> {
  label: React.ReactNode;
  capabilities?: string[];
  execute: (data: T[]) => Promise<string>;
  isActive?: (data: T[]) => boolean;
}

export interface BulkActionBarProps<T extends Record<string, unknown> = Record<string, unknown>> {
  selected: T[];
  prepareLabel: (selected: T[]) => React.ReactNode;
  actions: BulkAction<T>[];
  style?: React.CSSProperties;
}

const BulkActionContainer = styled(Csku)<{ $active: boolean; }>`
&&& {
  position: fixed;
  bottom: 0;
  height: 69px;
  width: 100%;
  padding: 8px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.$active ? colors.neutrals['100'] : colors.neutrals['80']};
  color: ${colors.white};
  margin: 0;
}`;

const ActionButtonContainer = styled(Csku)`
&&& {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 1rem;
}`;

function BulkActionButton(
  { action, onClick, disabled = false, executing = false }: {
    action: BulkAction,
    onClick: () => Promise<void>,
    disabled: boolean,
    executing: boolean
  }
) {
  const capable = useHasCapabilities(action.capabilities ?? []);
  if (!capable) {
    return null;
  }

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
    >
      {action.label}
    </Button>
  );
}

function BulkActionBar({ selected, prepareLabel, actions, style }: BulkActionBarProps) {
  const [executing, setExecuting] = useState<boolean>(false);
  const label = prepareLabel(selected);

  const renderedActions = actions.map(
    (action, index) => (
      <BulkActionButton
        key={`action-${index}`}
        action={action}
        disabled={executing || selected.length === 0 || (action.isActive && !action.isActive(selected))}
        executing={executing}
        onClick={async () => {
          try {
            setExecuting(true);
            const result = await action.execute(selected);
            if (result) {
              toast.success(result);
            }
          } catch (error) {
            toast.error(error)
          } finally {
            setExecuting(false);
          }
        }}
      />
    )
  ).filter(
    action => !!action
  );

  if (renderedActions.length === 0) {
    return null;
  }

  return (
    <BulkActionContainer $active={selected.length > 0} style={style}>
      {label}
      <ActionButtonContainer>{renderedActions}</ActionButtonContainer>
    </BulkActionContainer>
  );
}

export default BulkActionBar;
