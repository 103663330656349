import React from "react";
import { REPORT_TYPES } from "../routes/report_types";
import { ArrowIcon, Col, colors, Row, Typography } from "@commonsku/styles";
import styled from "styled-components";
import { Span } from "../../helpers/Typography";
import HomeLinkComponent from "./HomeLinkComponent";
import { useDispatch } from "react-redux";
import { clearCurrentReport } from "../../../redux/savedReports";
import { useHasCapabilities } from "../../../hooks";

const availableReports = Object.entries(REPORT_TYPES).map(
  ([reportType, value]) => ({
    reportType,
    value,
  }),
);

const ReportBox = styled.div`
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  padding: 16px 24px !important;
  height: 200px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 142, 160, 0.5);
    transform: scale(1.005);
  }
`;

const ReportTitle = styled(Typography.H5)`
  && {
    margin-top: 16px;
    color: ${colors.teal["65"]} !important;
  }
`;

const ReportSpan = styled(Typography.Span)`
  && {
    display: inline-block;
    max-height: 100px;
    margin-top: 4px;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const HomePage = () => {
  const dispatch = useDispatch();
  dispatch(clearCurrentReport());

  return (
    <Row>
      {availableReports.map((report) => {
        const capabilities = report.value.capabilities;
        if (!capabilities || capabilities.length && useHasCapabilities(capabilities)) {
          return <Col
            totalCols={2}
            lg={1}
            md={2}
            sm={2}
            xs={2}
            padded
            key={report.reportType}
          >
            <HomeLinkComponent url={report.value.v1Url || report.value.path}>
              <ReportBox>
                <div
                  style={{
                    width: `100%`,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ReportTitle>{report.value.title}</ReportTitle>
                    {report.value.new && (
                      <Span
                        bold
                        color="var(--color-pink-60)"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: 24,
                        }}
                      >
                        <ArrowIcon
                          direction="left"
                          size="default"
                          color="var(--color-pink-60)"
                        />
                        New!
                      </Span>
                    )}
                  </div>
                  <ReportSpan>{report.value.description}</ReportSpan>
                </div>
              </ReportBox>
            </HomeLinkComponent>
          </Col>
        }
      })}
    </Row>
  );
};

export default HomePage;
