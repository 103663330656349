import React, { useMemo, useState } from "react";
import { useReportContext } from "../report_context";
import BulkActionBar from "../../BulkActionBar";

function ActionBar({ style }: { style?: React.CSSProperties }) {
  const { fetchReport, firstLoaded, totalRows, selectedRows, reportConfig: { withSelection, bulkActions } } = useReportContext();  

  const actions = useMemo(
    () => (bulkActions?.actions ?? []).map(
      action => ({
        ...action,
        execute: action.refreshOnSuccess ? (async (selected) => {
          const result = await action.execute(selected);
          // Don't await so that we can do it simultaneously
          fetchReport();
          return result;
        }) : action.execute
      })
    ),
    [bulkActions, fetchReport]
  );

  if (!withSelection || !bulkActions || !firstLoaded || totalRows === 0) {
    return null;
  }
  return (
    <BulkActionBar
      selected={selectedRows}
      prepareLabel={bulkActions.prepareLabel}
      actions={actions}
      style={style}
    />
  );
}

export default ActionBar;
